import script from "./SettingsTab.vue?vue&type=script&setup=true&lang=js"
export * from "./SettingsTab.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__

/* vue-cli-plugin-import-components */

import DescriptionList from "@/components/neue/DescriptionList.vue";
import DescriptionListDropdown from "@/components/neue/DescriptionListDropdown.vue";
import DescriptionListEditableInput from "@/components/neue/DescriptionListEditableInput.vue";
import DescriptionListItem from "@/components/neue/DescriptionListItem.vue";
import FormElement from "@/components/neue/FormElement.vue";
import FormSection from "@/components/neue/FormSection.vue";
import InputField from "@/components/neue/InputField.vue";
import NeueButton from "@/components/neue/NeueButton.vue";
import NeueToggle from "@/components/neue/NeueToggle.vue";
import SaveBar from "@/components/SaveBar.vue";
import SecondaryNav from "@/components/SecondaryNav.vue";
import SecondaryTop from "@/components/SecondaryTop.vue";
import TheModal from "@/components/TheModal.vue";
script.components = Object.assign({}, { DescriptionList, DescriptionListDropdown, DescriptionListEditableInput, DescriptionListItem, FormElement, FormSection, InputField, NeueButton, NeueToggle, SaveBar, SecondaryNav, SecondaryTop, TheModal }, script.components);if ('__vccOpts' in script) { script.__o = script.__o || {}; script.__o.components = Object.assign({}, { DescriptionList, DescriptionListDropdown, DescriptionListEditableInput, DescriptionListItem, FormElement, FormSection, InputField, NeueButton, NeueToggle, SaveBar, SecondaryNav, SecondaryTop, TheModal }, script.__o.components);}