import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "flex-1 px-2 space-y-3" }
const _hoisted_2 = {
  class: "space-y-1",
  role: "group"
}
const _hoisted_3 = { class: "px-4 md:px-6 lg:px-8 pb-12" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "italic text-gray-300"
}
const _hoisted_6 = {
  key: 0,
  class: "italic text-gray-300"
}
const _hoisted_7 = ["src"]

import { reactive } from 'vue'
import { computed, onMounted } from 'vue'
import { ColorPicker } from 'vue-accessible-color-picker'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

import DescriptionList from '@/components/neue/DescriptionList'
import DescriptionListEditable from '@/components/neue/DescriptionListEditable'
import DescriptionListEditableInput from '@/components/neue/DescriptionListEditableInput'
import api from '@/store/api'
import { mapRelationship } from '@/store/mappers'


export default {
  __name: 'StorePrefs',
  setup(__props) {

const store = useStore()
const route = useRoute()

const user = computed(() => mapRelationship({ type: 'user', id: store.getters.loggedInUserId }))

const editing = reactive({
  name: false,
  head: false,
  meta: false,
  profile: false,
  banner: false,
  color: false,
  clickUrl: false
})

const local = reactive({
  name: '',
  head: '',
  meta: '',
  profile: null,
  banner: null,
  color: '',
  clickUrl: ''
})

const hasUnsavedChanges = computed(() => Object.values(editing).some(Boolean))

const profile = computed(() => mapRelationship(user.value?.relationships.profile_picture.data))
const banner = computed(() => mapRelationship(user.value?.relationships.banner_picture.data))

const setLocal = () => {
  for (const key of Object.keys(editing)) {
    editing[key] = false
  }
  local.name = user.value.attributes.user_name
  local.head = user.value.attributes.html_head_content
  local.meta = user.value.attributes.facebook_pixel_container_id
  local.profile = null
  local.banner = null
  local.color = user.value.attributes.color.hex ? `#${user.value.attributes.color.hex}` : '#000000ff'
  local.clickUrl = profile.value?.attributes?.click_url
}

onMounted(() => {
  api.call('GET', `users/${store.getters.loggedInUserId}`).then(setLocal)
})

const handleSubmit = () => {
  for (const key of Object.keys(editing)) {
    editing[key] = false
  }

  let profilePictureAttributes = {}

  if (local.profile) {
    profilePictureAttributes = local.profile
    if (local.clickUrl) {
      profilePictureAttributes.click_url = local.clickUrl
    } else {
      profilePictureAttributes.click_url = ''
    }
  } else if (profile.value && !local.clickUrl) {
    if (profile.value.id) {
      profilePictureAttributes = {
        _id: profile.value.id,
        click_url: null
      }
    } else {
      profilePictureAttributes = profile
    }
  } else if (!local.profile) {
    profilePictureAttributes = {
      _id: profile.value.id,
      click_url: local.clickUrl
    }
  }

  api.call('PUT', 'profile', {
    profile: {
      user_name: local.name,
      html_head_content: local.head,
      facebook_pixel_container_id: local.meta,
      profile_picture_attributes: profilePictureAttributes,
      banner_picture_attributes: local.banner,
      default_color: local.color
    }
  })
}

return (_ctx, _cache) => {
  const _component_router_link = _resolveComponent("router-link")
  const _component_SecondaryTop = _resolveComponent("SecondaryTop")
  const _component_SaveBar = _resolveComponent("SaveBar")
  const _component_ImageUploaderNeue = _resolveComponent("ImageUploaderNeue")
  const _component_SecondaryNav = _resolveComponent("SecondaryNav")

  return (_openBlock(), _createBlock(_component_SecondaryNav, null, {
    sidebar: _withCtx(() => [
      _createElementVNode("nav", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList([
              { name: 'Storefront', link: '/storefront' },
              { name: 'Domains', link: '/storefront/domains' },
              { name: 'Content blocks', link: '/storefront/modules' },
              { name: 'Links menu', link: '/storefront/links' }
            ], (item) => {
            return _createVNode(_component_router_link, {
              class: _normalizeClass(["w-full group flex items-center rounded-md px-3 py-1 text-sm font-semibold tracking-tight text-gray-600 hover:bg-gray-50 hover:text-gray-900", _unref(route).path === item.link && 'bg-white']),
              to: item.link
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.name), 1)
              ]),
              _: 2
            }, 1032, ["to", "class"])
          }), 64))
        ])
      ])
    ]),
    top: _withCtx(() => [
      _createVNode(_component_SecondaryTop, {
        text: "Storefront",
        "save-status": true
      }),
      _createVNode(_component_SaveBar, {
        "has-unsaved-changes": hasUnsavedChanges.value,
        onOnDiscard: setLocal,
        onOnSave: handleSubmit
      }, null, 8, ["has-unsaved-changes"])
    ]),
    main: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(DescriptionList), { title: "Storefront settings" }, {
          default: _withCtx(() => [
            _createVNode(_unref(DescriptionListEditableInput), {
              value: local.name,
              "onUpdate:value": _cache[0] || (_cache[0] = $event => ((local.name) = $event)),
              active: editing.name,
              "onUpdate:active": _cache[1] || (_cache[1] = $event => ((editing.name) = $event)),
              label: "Business name",
              placeholder: "Your Business Name"
            }, null, 8, ["value", "active"]),
            _createVNode(_unref(DescriptionListEditable), {
              active: editing.head,
              "onUpdate:active": _cache[3] || (_cache[3] = $event => ((editing.head) = $event)),
              label: "Header tag"
            }, {
              inactive: _withCtx(() => [
                (local.head)
                  ? (_openBlock(), _createElementBlock("pre", _hoisted_4, _toDisplayString(local.head), 1))
                  : (_openBlock(), _createElementBlock("pre", _hoisted_5, "<script>/* code here */<script>"))
              ]),
              active: _withCtx(() => [
                _withDirectives(_createElementVNode("textarea", {
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((local.head) = $event)),
                  rows: "5",
                  class: "shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
                }, null, 512), [
                  [_vModelText, local.head]
                ])
              ]),
              _: 1
            }, 8, ["active"]),
            _createVNode(_unref(DescriptionListEditableInput), {
              value: local.meta,
              "onUpdate:value": _cache[4] || (_cache[4] = $event => ((local.meta) = $event)),
              active: editing.meta,
              "onUpdate:active": _cache[5] || (_cache[5] = $event => ((editing.meta) = $event)),
              label: "Meta pixel",
              placeholder: "12345XXXXXXXXXXX"
            }, null, 8, ["value", "active"]),
            _createVNode(_unref(DescriptionListEditable), {
              active: editing.profile,
              "onUpdate:active": _cache[7] || (_cache[7] = $event => ((editing.profile) = $event)),
              label: "Business Logo"
            }, {
              inactive: _withCtx(() => [
                (!profile.value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, "None"))
                  : _createCommentVNode("", true),
                (profile.value)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      src: profile.value.attributes.secure_url,
                      class: "h-32"
                    }, null, 8, _hoisted_7))
                  : _createCommentVNode("", true)
              ]),
              active: _withCtx(() => [
                _createVNode(_component_ImageUploaderNeue, {
                  files: profile.value ? [profile.value] : [],
                  retained: true,
                  multiple: false,
                  onChange: _cache[6] || (_cache[6] = e => (local.profile = e))
                }, null, 8, ["files"])
              ]),
              _: 1
            }, 8, ["active"]),
            (profile.value || local.profile)
              ? (_openBlock(), _createBlock(_unref(DescriptionListEditableInput), {
                  key: 0,
                  value: local.clickUrl,
                  "onUpdate:value": _cache[8] || (_cache[8] = $event => ((local.clickUrl) = $event)),
                  active: editing.clickUrl,
                  "onUpdate:active": _cache[9] || (_cache[9] = $event => ((editing.clickUrl) = $event)),
                  label: "Logo Url",
                  description: "Customers clicking your store's logo will be redirected to the specified URL in a new tab",
                  placeholder: "https://www.business-url.com"
                }, null, 8, ["value", "active"]))
              : _createCommentVNode("", true),
            _createVNode(_unref(DescriptionListEditable), {
              active: editing.color,
              "onUpdate:active": _cache[11] || (_cache[11] = $event => ((editing.color) = $event)),
              label: "Store color"
            }, {
              inactive: _withCtx(() => [
                _createElementVNode("div", {
                  style: _normalizeStyle(`background-color: ${local.color}`),
                  class: "h-5 w-10"
                }, null, 4)
              ]),
              active: _withCtx(() => [
                _createVNode(_unref(ColorPicker), {
                  color: local.color,
                  "visible-formats": ['hex'],
                  "default-format": "hex",
                  "alpha-channel": "hide",
                  onColorChange: _cache[10] || (_cache[10] = 
                  e => {
                    if (local.color !== e.colors.hex) {
                      local.color = e.colors.hex
                    }
                  }
                )
                }, null, 8, ["color"])
              ]),
              _: 1
            }, 8, ["active"])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}
}

}