import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative mt-2" }
const _hoisted_2 = ["onClick"]

import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions
} from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import Fuse from 'fuse.js'
import { ref, watchEffect } from 'vue'


export default {
  __name: 'ComboInput',
  props: ['modelValue', 'name', 'options', 'queryFunction', 'option'],
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit

const fuse = new Fuse([], { keys: ['name'] })

const query = ref('')
const selectedOption = ref(props.modelValue)

const filteredOptions = ref([])

watchEffect(async () => {
  if (props.queryFunction) {
    filteredOptions.value = await props.queryFunction(query.value)
    return
  }

  if (!query.value) {
    filteredOptions.value = props.options
    return
  }

  fuse.setCollection(props.options)
  filteredOptions.value = fuse.search(query.value).map(x => x.item)
})

watchEffect(() => {
  emit('update:modelValue', selectedOption.value)
})

const selectOption = option => {
  selectedOption.value = option
  query.value = option.name
}

return (_ctx, _cache) => {
  return (_openBlock(), _createBlock(_unref(Combobox), {
    as: "div",
    modelValue: selectedOption.value,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((selectedOption).value = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(ComboboxLabel), { class: "block text-sm font-medium text-gray-700" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(props.name), 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(ComboboxInput), {
          autocomplete: "off",
          class: "w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6",
          value: query.value,
          onInput: _cache[0] || (_cache[0] = e => (query.value = e.target.value))
        }, null, 8, ["value"]),
        _createVNode(_unref(ComboboxButton), { class: "absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none" }, {
          default: _withCtx(() => [
            _createVNode(_unref(ChevronUpDownIcon), {
              class: "h-5 w-5 text-gray-400",
              "aria-hidden": "true"
            })
          ]),
          _: 1
        }),
        (filteredOptions.value.length)
          ? (_openBlock(), _createBlock(_unref(ComboboxOptions), {
              key: 0,
              class: "absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredOptions.value, (option) => {
                  return (_openBlock(), _createBlock(_unref(ComboboxOption), {
                    value: option,
                    as: "template"
                  }, {
                    default: _withCtx(({ active, selected }) => [
                      _createElementVNode("li", {
                        class: _normalizeClass(["relative cursor-default select-none py-2 pl-3 pr-9", active ? 'bg-brand-600 text-white' : 'text-gray-900']),
                        onClick: $event => (selectOption(option))
                      }, [
                        _createElementVNode("span", {
                          class: _normalizeClass(["block truncate", selected && 'font-semibold'])
                        }, _toDisplayString(option.name), 3),
                        (selectedOption.value && selectedOption.value.value === option.value)
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 0,
                              class: _normalizeClass(["absolute inset-y-0 right-0 flex items-center pr-4", active ? 'text-white' : 'text-brand-600'])
                            }, [
                              _createVNode(_unref(CheckIcon), {
                                class: "h-5 w-5",
                                "aria-hidden": "true"
                              })
                            ], 2))
                          : _createCommentVNode("", true)
                      ], 10, _hoisted_2)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 256))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

}