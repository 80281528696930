import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "border-b border-gray-200 w-full mb-8" }
const _hoisted_2 = {
  class: "-mb-px flex",
  "aria-label": "Tabs"
}

import algoliasearch from 'algoliasearch'
import { computed, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import api from '@/store/api'
import { mapType } from '@/store/mappers'


export default {
  __name: 'NeueOrderModal',
  props: ['isOpen', 'pair'],
  emits: ['closeModal'],
  setup(__props, { emit: __emit }) {

const client = algoliasearch(process.env.VUE_APP_ALGOLIA_APP_ID, process.env.VUE_APP_ALGOLIA_API_KEY_SEARCH)
const algoliaIndex = client.initIndex('ConversationUserPair')

const props = __props
const emit = __emit
const router = useRouter()
const vuex = useStore()

const stores = computed(() => mapType('store'))

const tab = ref('order')
const store = ref(null)
const pair = ref(null)

watch(
  () => props.isOpen,
  () => {
    if (!props.isOpen) return // only reset on open to avoid flash when closing modal
    tab.value = 'order'
    store.value = null
    pair.value = null
  }
)

const modalValid = computed(() => (props.pair || pair.value) && (store.value || tab.value === 'invoice'))

const search = async query => {
  const results = await algoliaIndex.search(query, {
    filters: 'user_id=' + vuex.getters.loggedInUserId
  })

  return results.hits.map(x => ({ value: x.id, name: x.conversation_title }))
}

const create = () => {
  api
    .call('POST', 'bottles', {
      bottle: {
        store_id: tab.value === 'order' ? store.value.value : undefined,
        type: tab.value === 'invoice' ? 'InvoiceBottle' : undefined,
        conversation_user_pair_id: props.pair || pair.value.value
      }
    })
    .then(response => {
      router.push('/order/' + response.data.data.id)
    })
  emit('closeModal')
}

onMounted(() => {
  api.call('GET', 'stores?included=none')
})

return (_ctx, _cache) => {
  const _component_ComboInput = _resolveComponent("ComboInput")
  const _component_FormElement = _resolveComponent("FormElement")
  const _component_FormSection = _resolveComponent("FormSection")
  const _component_NeueButton = _resolveComponent("NeueButton")
  const _component_TheModal = _resolveComponent("TheModal")

  return (_openBlock(), _createBlock(_component_TheModal, {
    "is-open": props.isOpen,
    "modal-title": "New order",
    onCloseModal: _cache[5] || (_cache[5] = $event => (_ctx.$emit('closeModal')))
  }, {
    main: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            type: "button",
            class: _normalizeClass([
              tab.value === 'order'
                ? 'border-brand-500 text-brand-600'
                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
              'w-1/2 border-b-2 py-4 px-1 text-center text-sm font-medium'
            ]),
            onClick: _cache[0] || (_cache[0] = $event => (tab.value = 'order'))
          }, " New order ", 2),
          _createElementVNode("button", {
            type: "button",
            class: _normalizeClass([
              tab.value === 'invoice'
                ? 'border-brand-500 text-brand-600'
                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
              'w-1/2 border-b-2 py-4 px-1 text-center text-sm font-medium'
            ]),
            onClick: _cache[1] || (_cache[1] = $event => (tab.value = 'invoice'))
          }, " Custom invoice ", 2)
        ])
      ]),
      (!(props.pair && tab.value === 'invoice'))
        ? (_openBlock(), _createBlock(_component_FormSection, { key: 0 }, {
            default: _withCtx(() => [
              (!props.pair)
                ? (_openBlock(), _createBlock(_component_FormElement, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ComboInput, {
                        modelValue: pair.value,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((pair).value = $event)),
                        name: "Customer",
                        option: pair.value,
                        "query-function": search
                      }, null, 8, ["modelValue", "option"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_FormElement, null, {
                default: _withCtx(() => [
                  (tab.value === 'order')
                    ? (_openBlock(), _createBlock(_component_ComboInput, {
                        key: 0,
                        modelValue: store.value,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((store).value = $event)),
                        option: store.value,
                        name: "Store",
                        options: stores.value.map(x => ({ name: x.attributes.store_name, value: x.id }))
                      }, null, 8, ["modelValue", "option", "options"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_NeueButton, {
        type: "secondary",
        onClick: _cache[4] || (_cache[4] = $event => (_ctx.$emit('closeModal')))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Cancel")
        ]),
        _: 1
      }),
      _createVNode(_component_NeueButton, {
        type: modalValid.value ? 'primary' : 'inactive',
        onClick: create
      }, {
        default: _withCtx(() => [
          _createTextVNode("Create")
        ]),
        _: 1
      }, 8, ["type"])
    ]),
    _: 1
  }, 8, ["is-open"]))
}
}

}