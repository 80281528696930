import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "py-4 sm:py-5 sm:pt-5" }
const _hoisted_2 = { class: "sm:grid sm:grid-cols-3 sm:gap-x-4 sm:flex items-center" }
const _hoisted_3 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_4 = { class: "mt-1 flex items-center text-sm text-gray-900 sm:col-span-2 sm:mt-0" }
const _hoisted_5 = {
  key: 0,
  class: "flex-grow"
}
const _hoisted_6 = {
  key: 1,
  class: "flex-grow"
}
const _hoisted_7 = {
  key: 2,
  class: "flex-grow"
}
const _hoisted_8 = {
  key: 3,
  class: "ml-4 flex flex-shrink-0 items-center justify-center"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "text-sm font-small text-gray-500 italic" }


export default {
  __name: 'DescriptionListItem',
  props: ['label', 'contents', 'description'],
  setup(__props) {

const props = __props

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("dt", _hoisted_3, _toDisplayString(props.label), 1),
      _createElementVNode("dd", _hoisted_4, [
        (props.contents)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(props.contents), 1))
          : _createCommentVNode("", true),
        (_ctx.$slots.contents)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
              _renderSlot(_ctx.$slots, "contents")
            ]))
          : _createCommentVNode("", true),
        (!props.contents && !_ctx.$slots.contents)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7))
          : _createCommentVNode("", true),
        (_ctx.$slots.default)
          ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
              _renderSlot(_ctx.$slots, "default")
            ]))
          : _createCommentVNode("", true)
      ]),
      (__props.description)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("p", _hoisted_10, _toDisplayString(__props.description), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

}