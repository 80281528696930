import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "flex-1 px-2 space-y-3",
  "aria-label": "Sell sidebar"
}
const _hoisted_2 = { class: "space-y-1" }
const _hoisted_3 = {
  class: "space-y-1",
  role: "group"
}

import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import api from '@/store/api'
import { mapType } from '@/store/mappers'


export default {
  __name: 'SellSidebar',
  setup(__props) {

const route = useRoute()

const moreMethods = ref(false)

const sections = computed(() => [
  {
    name: 'Stores',
    link: '/store/stores',
    children: mapType('store')
      .sort((a, b) => a.attributes.order - b.attributes.order)
      .map(x => ({
        name: x.attributes.store_name,
        link: '/store/stores/' + x.id
      }))
  },
  {
    name: 'Drops',
    link: '/store/drops'
  },
  {
    name: 'Product library',
    link: '/store/products',
    children: [
      { name: 'Folders', link: '/store/folders' }
      // { name: 'Products by store', link: '/store/products' }
    ]
  },
  {
    name: 'Labels',
    link: '/store/labels'
  },
  {
    name: 'Discount codes',
    link: '/store/discount-codes'
  },
  {
    name: 'Account credits',
    link: '/store/account-credits'
  },
  {
    name: 'Membership tiers',
    link: '/store/membership-tiers'
  },
  {
    name: 'Loyalty programs',
    link: '/store/loyalty-programs'
  },
  {
    name: 'Referral program',
    link: '/store/referral-program'
  },
  {
    name: 'Fulfillment methods',
    link: '/store/fulfillment-methods',
    children: mapType('fulfillment_method')
      .map(x => ({
        name: x.attributes.name,
        link: '/store/fulfillment-methods/' + x.id
      }))
      .slice(0, moreMethods.value ? Infinity : 4)
  }
])

onMounted(() => {
  api.call('GET', 'stores?included=none')
  api.call('GET', 'fulfillment_methods?included=none')
})

return (_ctx, _cache) => {
  const _component_router_link = _resolveComponent("router-link")
  const _component_NeueButton = _resolveComponent("NeueButton")

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sections.value, (section) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_router_link, {
          to: section.link,
          class: _normalizeClass(["w-full flex items-center rounded-md px-3 py-1 text-md font-bold tracking-tight text-gray-600 hover:bg-gray-50 hover:text-gray-900", 
          (section.children
            ? _unref(route).path === section.link || _unref(route).path === section.link + '/new'
            : _unref(route).path.startsWith(section.link)) && 'bg-white'
        ])
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(section.name), 1)
          ]),
          _: 2
        }, 1032, ["to", "class"]),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.children, (item) => {
            return (_openBlock(), _createBlock(_component_router_link, {
              to: item.link,
              class: _normalizeClass(["w-full group flex items-center rounded-md px-3 py-1 text-sm font-medium tracking-tight text-gray-500 hover:bg-gray-50 hover:text-gray-900 truncate", _unref(route).path === item.link && _unref(route).path !== '/store/products' && 'bg-white'])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.name), 1)
              ]),
              _: 2
            }, 1032, ["to", "class"]))
          }), 256))
        ]),
        (section.name === 'Fulfillment methods' && !moreMethods.value && _unref(mapType)('fulfillment_method').length > 4)
          ? (_openBlock(), _createBlock(_component_NeueButton, {
              key: 0,
              type: "secondary",
              class: "mx-3",
              onClick: _cache[0] || (_cache[0] = $event => (moreMethods.value = true))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Show more ")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]))
    }), 256))
  ]))
}
}

}