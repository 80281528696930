let state = {
  objects: {},
  savingCount: 0,
  omniSearchOpen: false,
  pendingMessages: {},
  loggedInUser: {},
  hideNumbers: false,
  ongoingCall: null,
  pusher: null
}

export default state
