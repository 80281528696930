import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "mt-1" }
const _hoisted_3 = ["id", "value", "placeholder", "aria-describedby"]
const _hoisted_4 = ["id"]
const _hoisted_5 = {
  key: 2,
  class: "mt-2 text-sm text-red-500"
}

import { ulid } from 'ulid'
import { ref } from 'vue'

export default {
  __name: 'InputArea',
  props: {
  modelValue: String,
  name: String,
  description: String,
  placeholder: String,
  validateProfanity: { type: Boolean, default: false }
},
  emits: ['update:modelValue', 'validationResponse'],
  setup(__props, { emit: __emit }) {

const id = ulid()
const errorMessage = ref('')

const handleInput = (emission, value) => {
  const forbiddenWords = findForbiddenWords(value)
  if (props.validateProfanity) {
    if (forbiddenWords.length === 1) {
      errorMessage.value = `We've detected a banned word in the message above. Please remove the word ${forbiddenWords[0]} to continue.`
      emit('validationResponse', false)
    } else if (forbiddenWords.length > 1) {
      errorMessage.value = `We've detected banned words in the message above. Please remove the following words to continue:  ${forbiddenWords.join(', ')}.`
      emit('validationResponse', false)
    } else {
      emit('validationResponse', true)
      errorMessage.value = null
    }
  }

  emit('update:modelValue', value)
}

const findForbiddenWords = value => {
  const forbiddenWords = [
    'get-rich-quick',
    'pyramid scheme',
    'MLM',
    'betting',
    'casino',
    'poker',
    'win big',
    'adult services',
    'escort',
    'porn',
    'CBD',
    'cannabis',
    'weed',
    'marijuana',
    'opioid',
    'THC',
    'quick loan',
    'debt relief',
    'credit repair',
    "you've won",
    'free money',
    'prescription drugs',
    'pharmacy without a prescription',
    'unclaimed prize',
    'limited time offer',
    'risk-free',
    'investment opportunity',
    'make money fast',
    'guaranteed income',
    'lottery',
    'prize claim',
    'double your money',
    'instant approval',
    'no credit check',
    'viagra',
    'levitra',
    'xanax',
    'tramadol',
    'opioids',
    'fentanyl',
    'heroin',
    'meth',
    'ecstasy',
    'cocaine',
    'steroids',
    'escort service',
    'sex',
    'nude',
    'scam',
    'fraud',
    'spam',
    'counterfeit',
    'bribe',
    'extortion',
    'blackmail',
    'terrorism',
    'bomb',
    'weapon',
    'gun',
    'firearm',
    'ammunition',
    'explosive',
    'murder',
    'kill',
    'suicide',
    'self-harm',
    'rape',
    'racist',
    'hate speech',
    'nazi',
    'swastika',
    'assassination',
    'fuck',
    'shit',
    'bitch',
    'asshole',
    'bastard',
    'cunt',
    'dick',
    'pussy',
    'cock',
    'slut',
    'whore',
    'faggot',
    'nigger',
    'kike',
    'chink',
    'spic'
  ]

  const foundWords = forbiddenWords.filter(word => {
    const regex = new RegExp(`\\b${word}\\b`, 'i')
    return regex.test(value)
  })

  return foundWords.map(word => {
    return `"${word}"`
  })
}

const props = __props

const emit = __emit

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (props.name)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _unref(id),
          class: "block text-sm font-medium text-gray-700 mb-1"
        }, _toDisplayString(props.name), 9, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("textarea", {
        id: _unref(id),
        rows: "4",
        class: "block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm placeholder:text-gray-500",
        value: props.modelValue,
        placeholder: props.placeholder,
        "aria-describedby": _unref(id) + '-description',
        onInput: _cache[0] || (_cache[0] = e => handleInput('update:modelValue', e.target.value))
      }, null, 40, _hoisted_3)
    ]),
    (props.description)
      ? (_openBlock(), _createElementBlock("p", {
          key: 1,
          id: _unref(id) + '-description',
          class: "mt-2 text-sm text-gray-500"
        }, _toDisplayString(props.description), 9, _hoisted_4))
      : _createCommentVNode("", true),
    (errorMessage.value)
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(errorMessage.value), 1))
      : _createCommentVNode("", true)
  ]))
}
}

}