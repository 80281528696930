import script from "./ProductList.vue?vue&type=script&setup=true&lang=js"
export * from "./ProductList.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__

/* vue-cli-plugin-import-components */

import BasicSpinner from "@/components/BasicSpinner.vue";
import EmptyState from "@/components/neue/EmptyState.vue";
import FormElement from "@/components/neue/FormElement.vue";
import FormSection from "@/components/neue/FormSection.vue";
import NeueButton from "@/components/neue/NeueButton.vue";
import NeueLoading from "@/components/neue/NeueLoading.vue";
import NeuePagination from "@/components/neue/NeuePagination.vue";
import NeueTablist from "@/components/neue/NeueTablist.vue";
import NeueToggle from "@/components/neue/NeueToggle.vue";
import SecondaryNav from "@/components/SecondaryNav.vue";
import SecondaryTop from "@/components/SecondaryTop.vue";
import SelectInput from "@/components/neue/SelectInput.vue";
import TablistHeader from "@/components/neue/TablistHeader.vue";
import TablistRow from "@/components/neue/TablistRow.vue";
import TheModal from "@/components/TheModal.vue";
script.components = Object.assign({}, { BasicSpinner, EmptyState, FormElement, FormSection, NeueButton, NeueLoading, NeuePagination, NeueTablist, NeueToggle, SecondaryNav, SecondaryTop, SelectInput, TablistHeader, TablistRow, TheModal }, script.components);if ('__vccOpts' in script) { script.__o = script.__o || {}; script.__o.components = Object.assign({}, { BasicSpinner, EmptyState, FormElement, FormSection, NeueButton, NeueLoading, NeuePagination, NeueTablist, NeueToggle, SecondaryNav, SecondaryTop, SelectInput, TablistHeader, TablistRow, TheModal }, script.__o.components);}