import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex-1 px-2 space-y-3" }
const _hoisted_2 = {
  class: "space-y-1",
  role: "group"
}
const _hoisted_3 = { class: "px-4 md:px-6 lg:px-8 pb-12" }
const _hoisted_4 = { class: "mt-6" }

import { XCircleIcon } from '@heroicons/vue/24/outline'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'

import NeueButton from '@/components/neue/NeueButton'
import NeueTable from '@/components/neue/NeueTable'
import TableCell from '@/components/neue/TableCell'
import TableHeadCell from '@/components/neue/TableHeadCell'
import TableRow from '@/components/neue/TableRow'
import api from '@/store/api'
import { mapType } from '@/store/mappers'


export default {
  __name: 'DomainsNeue',
  setup(__props) {

const route = useRoute()

const modalOpen = ref(false)
const name = ref('')

const domains = computed(() => mapType('subdomain'))

api.call('GET', 'domains')

const addDomain = () => {
  api.call('POST', 'domains', { subdomain: { name: name.value } })
  modalOpen.value = false
  name.value = ''
}

const deleteDomain = domain => {
  api.call('DELETE', 'domains/' + domain.id)
}

return (_ctx, _cache) => {
  const _component_router_link = _resolveComponent("router-link")
  const _component_SecondaryTop = _resolveComponent("SecondaryTop")
  const _component_InputField = _resolveComponent("InputField")
  const _component_FormElement = _resolveComponent("FormElement")
  const _component_FormSection = _resolveComponent("FormSection")
  const _component_TheModal = _resolveComponent("TheModal")
  const _component_SecondaryNav = _resolveComponent("SecondaryNav")

  return (_openBlock(), _createBlock(_component_SecondaryNav, null, {
    sidebar: _withCtx(() => [
      _createElementVNode("nav", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList([
              { name: 'Storefront', link: '/storefront' },
              { name: 'Domains', link: '/storefront/domains' },
              { name: 'Content blocks', link: '/storefront/modules' },
              { name: 'Links menu', link: '/storefront/links' }
            ], (item) => {
            return _createVNode(_component_router_link, {
              class: _normalizeClass(["w-full group flex items-center rounded-md px-3 py-1 text-sm font-semibold tracking-tight text-gray-600 hover:bg-gray-50 hover:text-gray-900", _unref(route).path === item.link && 'bg-white']),
              to: item.link
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.name), 1)
              ]),
              _: 2
            }, 1032, ["to", "class"])
          }), 64))
        ])
      ])
    ]),
    top: _withCtx(() => [
      _createVNode(_component_SecondaryTop, {
        text: "Domains",
        "save-status": true
      })
    ]),
    main: _withCtx(() => [
      _createVNode(_component_TheModal, {
        "is-open": modalOpen.value,
        "modal-title": "Add a domain",
        onCloseModal: _cache[3] || (_cache[3] = $event => (modalOpen.value = false))
      }, {
        main: _withCtx(() => [
          _createVNode(_component_FormSection, {
            onSubmit: _cache[1] || (_cache[1] = _withModifiers($event => (name.value && addDomain()), ["prevent"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_FormElement, null, {
                default: _withCtx(() => [
                  _createVNode(_component_InputField, {
                    modelValue: name.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((name).value = $event)),
                    name: "Domain name"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        footer: _withCtx(() => [
          _createVNode(_unref(NeueButton), {
            type: "secondary",
            onClick: _cache[2] || (_cache[2] = $event => (modalOpen.value = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode("Cancel")
            ]),
            _: 1
          }),
          _createVNode(_unref(NeueButton), {
            type: name.value ? 'primary' : 'inactive',
            onClick: addDomain
          }, {
            default: _withCtx(() => [
              _createTextVNode("Save")
            ]),
            _: 1
          }, 8, ["type"])
        ]),
        _: 1
      }, 8, ["is-open"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(NeueTable), null, {
            head: _withCtx(() => [
              _createVNode(_unref(TableHeadCell), null, {
                default: _withCtx(() => [
                  _createTextVNode("Name")
                ]),
                _: 1
              }),
              _createVNode(_unref(TableHeadCell), null, {
                default: _withCtx(() => [
                  _createTextVNode("CNAME")
                ]),
                _: 1
              }),
              _createVNode(_unref(TableHeadCell), { class: "flex w-full justify-end -m-1" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(NeueButton), {
                    type: "secondary",
                    onClick: _cache[4] || (_cache[4] = _withModifiers($event => (modalOpen.value = true), ["prevent"]))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("+ Domain")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(domains.value, (domain) => {
                return (_openBlock(), _createBlock(_unref(TableRow), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(TableCell), { class: "font-medium text-gray-700" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(domain.attributes.name), 1)
                      ]),
                      _: 2
                    }, 1024),
                    (domain.attributes.cname)
                      ? (_openBlock(), _createBlock(_unref(TableCell), { key: 0 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(domain.attributes.cname), 1)
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true),
                    (domain.attributes.errors?.message)
                      ? (_openBlock(), _createBlock(_unref(TableCell), {
                          key: 1,
                          class: "text-red-500"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(domain.attributes.errors.message), 1)
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true),
                    _createVNode(_unref(TableCell), { class: "flex w-full justify-end align-center" }, {
                      default: _withCtx(() => [
                        (domain.attributes.deletable)
                          ? (_openBlock(), _createBlock(_unref(XCircleIcon), {
                              key: 0,
                              class: "cursor-pointer h-6 w-6 hover:text-black",
                              onClick: $event => (deleteDomain(domain))
                            }, null, 8, ["onClick"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 256))
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}
}

}