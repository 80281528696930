import * as Sentry from '@sentry/vue'

import api from '@/store/api'

let actions = {
  getLoggedInUser({ commit, getters }) {
    return api.call('GET', `users/${getters.loggedInUserId}`).then(response => {
      commit('GET_USER', response.data)
      commit('SET_LOGGED_IN_USER', response.data)
      commit('SET_HIDE_NUMBERS', response.data.data.attributes.hide_financial_data)
      if (process.env.NODE_ENV === 'production') {
        Sentry.setUser({
          domain: window.location.hostname,
          id: response.data.data.attributes.id,
          username: response.data.data.attributes.handle
        })
      }
    })
  },

  setOngoingCall({ commit }, call) {
    commit('SET_ONGOING_CALL', call)
  },
  setSignUpPropertyValues({ commit }, data) {
    commit('SIGNUP_PROPERTY_VALUES', data)
  },
  clearSignUpPropertyValues({ commit }) {
    commit('SIGNUP_PROPERTY_VALUES', null)
  }
}

export default actions
